<template>
	<div class="vip">
		<div class="vip_banner">
			<img src="../../../assets/person/vip.png" v-if="lang=='zh'">
			<img src="../../../assets/person/vip_bnm.png" v-else>
		</div>
		<div class="login_change">
			<div class="item" @click="curSelect=1">
				<div class="item_title"
					:style="{color:curSelect==1?'#E60012':'#999999',fontWeight:curSelect==1?'bold':400}">
					{{$t('my.ordinaryPackage')}}
				</div>
				<div class="img" v-if="curSelect==1">
					<img src="@/assets/login/select.png">
				</div>
			</div>
			<div class="item" @click="curSelect=2">
				<div class="item_title"
					:style="{color:curSelect==2?'#E60012':'#999999',fontWeight:curSelect==2?'bold':400}">
					{{$t('my.vipPackage')}}
				</div>
				<div class="img" v-if="curSelect==2">
					<img src="@/assets/login/select.png">
				</div>
			</div>
		</div>
		<div class="vip_card">
			<div class="vip_card_left" @click="rightClick">
				<img src="../../../assets/person/left.png">
			</div>
			<div id="vip_all_card">
				<div @click="vipItemClick(index)" class="card_item" v-for="(item,index) in vipList" :key="index"
					:style="{backgroundImage: 'url(' + require(curIndex==index?'@/assets/person/vip_select.png':'@/assets/person/vip_unselect.png') + ')'}">
					<div class="card_count" :style="{color: curIndex==index?'#8D5710':'#333333',}">{{item.bnm_name}}
					</div>
					<div class="card_price" :style="{color: curIndex==index?'#8D5710':'#333333',}">${{item.price}}</div>
					<div class="card_small_price" :style="{color: curIndex==index?'#8D5710':'#999999',}">
						${{item.show_price}}
					</div>
				</div>
			</div>
			<div class="vip_card_right" @click="leftClick">
				<img src="../../../assets/person/right.png">
			</div>
		</div>

		<div class="vip_rights">
			<div class="vip_img">
				<img src="../../../assets/person/vip_rights.png" v-if="lang=='zh'">
				<img style="width: 452px;" v-else src="../../../assets/person/vip_rights_bnm.png">
			</div>
			<div class="vip_infor">
				<div class="vip_infor_item" v-for="(item,index) in vipList" :key="index">
					<span v-html="item.content"></span>
				</div>
			</div>
		</div>

		<div class="select_pay_way">
			<div class="pay_way_title">
				<div class="pay_way_img">
					<img src="../../../assets/person/circle.png">
				</div>
				<div class="pay_way_text">
					{{$t('my.selectPayWay')}}
				</div>
			</div>
			<div class="pay_way_select">
				<div :class="['pay_way_item',checkIndex==index?'check':'un_check' ]" v-for="(item,index) in 1"
					:key="index" @click="checkItem(index)">
					<img src="../../../assets/person/check.png" class="check_img" v-if="checkIndex==index">
					<img :src="require(`../../../assets/person/4.png`)" class="logo_img">
					<!-- <img :src="require(`../../../assets/person/${index+1}.png`)" class="logo_img"> --><!--曹梦玲写的-->
				</div>
			</div>
		</div>

		<div class="rest_count" v-if="userInfor.vip==1">
			{{$t('my.remainingNumber')}}：{{userInfor.vip_information}}
		</div>
		<!-- <div class="rest_count" v-if="userInfor.vip==2">
			{{$t('my.validity')}}：{{userInfor.vip_last_time}}
		</div> -->
		<el-button class="vip_btn" @click="toPayNow" :loading="loading">
			{{$t('my.buyNow')}}
		</el-button>
		<!-- <div class="vip_tip">
			购买即代表同意<span>《巴拿马协议》</span>
		</div> -->
	</div>
</template>

<script>
	export default {
		data() {
			return {
				curSelect: 1,
				curIndex: 0, //vip当前选中
				vipList: [], //会员列表
				userInfor: '',
				payMoney: '', //支付金额
				lang: '',
				checkIndex: 0, //付款方式点击
				loading:false,
			}
		},
		watch: {
			curSelect() {
				this.curIndex = 0;
				this.getVipList();
			},
		},
		mounted() {
			this.lang = localStorage.getItem('lang');
			window.addEventListener("setItemEvent", (e) => {
				if (e.key === "lang") {
					this.lang = e.newValue;
				}
			})
			this.getVipList();
			this.getUserInfo();

		},
		methods: {
			// 付款方式点击
			checkItem(index) {
				this.checkIndex = index;
			},
			getVipList() {
				this.$http.vipList({
					type: this.curSelect,
				}).then(res => {
					if (res.code == 1) {
						this.vipList = res.data;
					} else {
						this.$message.info(res.msg);
					}
				})
			},
			getUserInfo() {
				this.$http.userInfo().then(res => {
					if (res.code == 1) {
						console.log("userInfor", res);
						this.userInfor = res.data;
					} else {
						this.$message.error(res.msg);
					}
				})
			},
			rightClick() {
				var all_card = document.getElementById("vip_all_card");
				all_card.scrollLeft += 100;
			},
			leftClick() {
				var all_card = document.getElementById("vip_all_card");
				all_card.scrollLeft -= 100;
			},
			// 会员卡片点击
			vipItemClick(index) {
				this.curIndex = index;
			},
			

			// 立即支付
			toPayNow() {
				this.payMoney = this.vipList[this.curIndex].price;
				let vipId = this.vipList[this.curIndex].id;
                if(this.loading==true){
					 this.$message.info(this.$t('other.repeatText'));
					 return false;
				}
				let data = {
					vip_id: vipId,
					payable_money: this.payMoney,
				}
				this.loading=true;
				this.$http.vipAddOrder(data).then(res => {
					if (res.code == 1) {
						this.checkPayWay(res.data.order_sn);
					} else {
						this.loading=false;
						this.$message.info(res.msg);
					}
				}).catch((err)=>{

					this.$message.info('系统错误');
					this.loading=false;
				})
			},
			// 选择支付方式
			checkPayWay(order_sn) {
				this.paypalPay(order_sn);
				// 曹梦玲写的
				// switch (this.checkIndex * 1) {
				// 	case 0:
				// 		break;
				// 	case 1:
				// 		break;
				// 	case 2:
				// 		break;
				// 	case 3:
				// 		this.paypalPay(order_sn);
				// 		break;
				// 	case 4:
				// 		break;
				// 	case 5:
				// 		break;
				// }
			},

			// paypal支付
			paypalPay(orderSn) {
				
				this.$http.getPayPal({
					order_sn: orderSn,
				}).then(res => {
					if (res.code == 1) {
						this.loading=false;
						let payUrl = res.data;
						console.log(payUrl);
						// return false;
						window.open(payUrl);
					}
				})
			}
		},
	}
</script>

<style scoped="" lang="less">
	.vip {
		padding-bottom: 47px;
	}

	.vip_banner {
		img {
			height: 348px;
		}
	}

	.login_change {
		padding: 24px 0 32px;
		display: flex;
		justify-content: center;
		font-size: 20px;

		.item {
			cursor: pointer;

			margin-right: 64px;

			.item_title {
				font-weight: 500;
			}
		}

		.img {
			img {
				height: 11px;
			}
		}
	}

	.vip_rights {
		margin-top: 42px;
		margin-bottom: 32px;

		.vip_img {
			margin: 0 auto;

			img {
				width: 244px;
				height: 34px;
			}
		}

		.vip_infor {
			margin-top: 20px;

			.vip_infor_item {
				color: #666666;
				font-size: 14px;
				margin-bottom: 24px;
			}
		}
	}

	.vip_btn {
		margin: 0px auto 16px;
		width: 308px;
		text-align: center;
		height: 48px;
		// line-height: 48px;
		font-size: 16px;
		color: #FFFFFF;
		background: linear-gradient(90deg, #E5C69B 0%, #D2A964 48%, #CCA054 100%);
		opacity: 1;
		border-radius: 44px;

		&:hover {
			cursor: pointer;
		}
	}

	.vip_tip {
		font-size: 12px;
		word-break: break-all;

		span {
			color: #3E62B1;
		}
	}

	.vip_card {
		display: flex;
		box-sizing: border-box;
		justify-content: center;
		position: relative;

		.vip_card_left {
			position: absolute;
			left: 0;
			top: 32%;
		}

		.vip_card_right {
			position: absolute;
			right: 0;
			top: 32%;
		}

		#vip_all_card {
			display: flex;
			width: 760px;
			overflow-x: scroll;
			padding: 12px 0;

			.card_item {
				min-width: 158px;
				height: 180px;
				// padding: 20px 32px;
				display: inline-block;
				background-image: url(../../../assets/person/vip_select.png);
				background-repeat: no-repeat;
				background-size: 100% 100%;
				margin-right: 8px;

				.card_count {
					padding-top: 48px;
					font-size: 16px;
				}

				.card_price {
					margin: 16px 0 8px;
					font-size: 21px;
				}

				.card_small_price {
					font-size: 13px;
					text-decoration: line-through;
				}


			}
		}
	}

	.rest_count {
		margin-bottom: 16px;
		color: #E60012;
		font-size: 14px;
	}


	.select_pay_way {
		width: 814px;
		margin: 0 auto;
		padding-top: 40px;
		padding-bottom: 40px;
		border-top: 1px dashed #DDDDDD;

		.pay_way_title {
			display: flex;
			align-items: center;
			text-align: left;


			.pay_way_img {
				display: flex;
				align-items: center;

				img {
					width: 18px;
					height: 18px;
				}
			}

			.pay_way_text {
				margin-left: 12px;
				font-size: 16px;
				font-weight: bold;
			}
		}


		.pay_way_select {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			margin-top: 24px;

			.pay_way_item {
				cursor: pointer;
				margin-top: 24px;
				width: 220px;
				height: 56px;
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 8px;
				position: relative;

				.check_img {
					width: 32px;
					height: 32px;
					position: absolute;
					top: 0;
					right: 0;
				}

				.logo_img {
					width: 44px;
					height: 44px;
				}
			}

			.un_check {
				border: 1px solid #EAEAEA;
			}

			.check {
				border: 1px solid #B62413;
			}
		}
	}
</style>
